<template>
  <div class="partner">
    <div class="container">
      <div class="about-title">
        <h1 class="primary-title">产品介绍</h1>
        <h2 class="secondary-title">INTRODUCE</h2>
      </div>
      <div class="about-content">
        <div class="about-item-content">
          <div class="about-item-title">嗨喵悦动是什么</div>
          <div class="about-item-body">
            <p>
              嗨喵悦动是LED大屏场景下用户通过手机微信参与的互联网互动服务平台，只需一块大屏幕和一台电脑就能引爆现场气氛。可以将线下用户转化为线上粉丝，适用于各种会议活动现场
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">现场用户如何参与大屏幕互动</div>
          <div class="about-item-body">
            <p>
              用户使用微信扫一扫，扫描大屏幕二维码参与互动，或者可以通过让用户关注企业公众号，参与互动。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">如何使用</div>
          <div class="about-item-body">
            <p>
              访问<strong
                ><a href="https://www.hudongmiao.com/" target="_blank"
                  >官网</a
                ></strong
              >，注册账号后，创建活动后对活动进行大屏幕的配置，并参考<strong
                ><a href="">《使用帮助》</a></strong
              >尽快进行测试操作，如您在使用过程中遇到任何问题，请联系我们的客服人员。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">现场设备</div>
          <div class="about-item-body">
            <p>
              方案1：现场准备一台电脑：用于大屏幕展示，链接LED大屏幕，需联网，均需安装现代浏览器（推荐使用<strong
                ><a href="https://www.google.cn/chrome/" target="_blank"
                  >Chrome</a
                ></strong
              >浏览器）；
            </p>
            <p>
              方案2：现场准备一台电脑，在投影时可以采用扩展模式，扩展桌面用于大屏幕展示，电脑桌面用于信息审核
            </p>
            <p>
              网络要求：20M，建议连接大屏幕电脑的网络单独提供（如：4G热点），不要与现场WIFI混在一起，以免现场用户太多导致网络拥塞，从而影响了现场效果
            </p>
            <p>
              建议配备专门的人员审核用户发送的消息，平台只对一些简单的垃圾话，脏话做了过滤处理。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">投影分辨率</div>
          <div class="about-item-body">
            <p>
              为了确保最佳显示效果，我们建议投影使用的分辨率为1024 * 768，1366 *
              768，1920 * 1080
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">正式获得前需要哪些准备</div>
          <div class="about-item-body">
            <p>
              建议在大屏幕直播前做一下调试工作，现场的投影、网络、手机网络最好经过测试验证。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">包含功能</div>
          <div class="about-item-body">
            <p>
              背景&音乐 、签到 、手写签到 、启动仪式 、倒计时 、Logo签到
              、3D签到 、签到欢迎页 、自定义版权 、霸屏 、抢红包 、弹幕
              、消息上墙 、摇大奖 、红包雨 、语音夺宝 、经典抽奖 、炫酷抽奖
              、摇奖机 、名单抽奖 、炫光抽奖 、3D抽奖 、答题闯关 、拔河 、赛车
              、数钱 、马拉松 、我是骑手 、摇一摇 、团队摇一摇 、水上乐园
              、点亮Logo 、添砖加瓦 、好运狂犇 、牛气冲天 、摇汤圆
              、龙舟(团队赛) 、龙舟(个人赛) 、吃月饼 、快乐圣诞 、小猪快跑
              、财源滚滚 、鼠你最牛 、投票 、评委评分 、打赏 、地理位置限制
              、活动流程 、照片墙 、对对碰 、现场提问 、活动微站 等
            </p>
          </div>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>

<script>
import myFooter from '@/components/myFooter.vue';

export default {
  name: 'introduce',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇红包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  components: { myFooter },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  position: relative;
}
.banner > .img {
  width: 100%;
  height: 50%;
  background-color: #f9f9f9;
  vertical-align: middle;
  border: 0;
}

.about-title {
  top: 15%;
  padding: 15px 37.5px;
  width: 100%;
  color: #000;
  position: relative;
}

.primary-title {
  font-size: 30px;
}

.secondary-title {
  margin-top: 7.5px;
  padding-left: 22.5px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Times New Roman", serif;
}

.container {
  position: relative;
}

.about-content {
  margin: 0px auto 0;
  padding: 22.5px 37.5px;
  width: 100%;
  max-width: 817.5px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 15px 11.25px -3.75px #d3cfc5;
}

.about-item-content {
  margin-top: 7.5px;
}

.about-item-title {
  color: #ff4141;
  font-size: 12px;
  margin-bottom: 15px;
  font-weight: bolder;
}

.about-item-body {
  margin-bottom: 22.5px;
  line-height: 1.5;
  text-indent: 2em;
}

p {
  margin: 0 0 7.5px;
}
</style>
